import React from 'react';

// Components
import EmptyGridResource from '../../../components/common/EmptyGridResource';
import MicrocontentCardSkeleton from '../../../components/common/MicrocontentCardSkeleton';
import GridResource from '../../../components/common/GridResource';
import DownloadableCard from './downloadable/DownloadableCard';

const Downloadable = (props) => {
    const { downloadable, emptyItems, loading } = props;

    return downloadable === null || loading ? (
        <EmptyGridResource
            propsValues={emptyItems}
            Card={MicrocontentCardSkeleton}
        />
    ) : (
        <GridResource
            propsValues={downloadable}
            type={'micro'}
            Card={DownloadableCard}
        />
    );
};

export default Downloadable;
