import React from 'react';
import styled from 'styled-components';

// Material UI
import DownloadIcon from '@mui/icons-material/Download';

const DownloadButton = (props) => {
    const { link, id } = props;

    return (
        <>
            <Container
                key={id}
                target="_blank"
                href={link}
                download
            >
                <DownloadIcon />
                Descargar
            </Container>
        </>
    );
};

export default DownloadButton;

const Container = styled.a`
    background-color: #b31d15;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 130px;
    border-radius: 100px;
    padding: 0.8rem;
    gap: 8px;
    color: white;
    svg {
        font-size: 1.5rem;
    }
`;
