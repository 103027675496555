import { useEffect, useReducer } from 'react';

const useFetch = ({
    asyncFn = () => {},
    adapter = null,
    dependencies = [],
}) => {
    const initialState = {
        data: null,
        loading: false,
        isError: false,
        error: null,
    };

    useEffect(() => {
        fetchService(asyncFn);

        return () => {
            dispatch(initialState);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    const [state, dispatch] = useReducer((prev, next) => {
        return { ...prev, ...next };
    }, initialState);

    async function fetchService(asyncFn) {
        dispatch({ ...initialState, loading: true });

        const response = await asyncFn();

        if (response?.error) {
            dispatch({
                data: null,
                loading: false,
                isError: true,
                error: response.error,
            });
            return;
        }

        dispatch({
            data: adapter ? adapter(response) : response,
            loading: false,
            isError: false,
            error: null,
        });
    }

    const refetch = () => {
        fetchService(asyncFn);
    };

    return {
        data: state.data,
        loading: state.loading,
        isError: state.isError,
        error: state.error,
        refetch,
    };
};

export default useFetch;
