import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import DateCardHome from "./DateCardHome";
import { getOndemandEvents } from "../../../redux/api/events/events";
import { Text } from "../../../components/common/Texts";
import OutlinedButtonWithIcon from "./OutlinedButtonWithIcon";

const DatesInCompany = () => {
  const ondemandId = useSelector((state) => state?.auth?.user?.ondemand_id);
  const { primary_color } = useSelector((state) => state?.ondemand?.ondemand);

  const { account } = useSelector((state) => state?.ondemand?.ondemand);
  const history = useHistory();

  //EFFECTS
  const [events, setEvents] = useState(null);

  useEffect(() => {
    getCompanyEvents(ondemandId);
  }, []);

  // FUNCTIONS
  const getCompanyEvents = async (ondemandId) => {
    const response = await getOndemandEvents(ondemandId);
    if (response.error || response === "No se encontraron eventos") {
      setEvents([]);
      return;
    }
    setEvents(response);
  };

  const GoToSchedulePage = () => {
    history.push(`/calendario`);
  };

  if (events === null) {
    return <Skeleton h="500px" w="100%" />;
  }
  return (
    <Container>
      <Header>
        <Text fontSize="14px" fontWeight="700" color="#000000">
          Proximas Clases
        </Text>
        <OutlinedButtonWithIcon
          icon={<TodayRoundedIcon />}
          text="Ver calendario"
          color={primary_color ? primary_color : "#b31d15"}
          action={GoToSchedulePage}
        />
      </Header>
      <AccountDiv>
        <Text
          fontSize="18px"
          fontWeight="700"
          color={primary_color ? primary_color : "#b31d15"}
        >
          Agenda {account}{" "}
        </Text>
      </AccountDiv>
      <EventCardList>
        {events.length !== 0 &&
          events.map((event) => (
            <DateCardHome
              key={event.id}
              event={event}
              primary_color={primary_color}
            />
          ))}
      </EventCardList>
    </Container>
  );
};

export default DatesInCompany;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  align-items: stretch;
  width: calc(50% - 1rem);
  padding: 0.2rem 1rem;
  height: calc(100% - 0.4rem);
  gap: 1rem;
  border-radius: 20px;
  box-shadow: 0px 8px 8px -4px #18274b14, 0px 4px 6px -4px #18274b1f;
  position: relative;

  @media (max-width: 768px) {
    min-height: 470px;
    height: 430px;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const AccountDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background: #f9f9f9;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 8px 24px -4px #18274b14;
  box-shadow: 0px 6px 12px -6px #18274b1f;
`;

const EventCardList = styled.div`
  position: relative;
  overflow-y: scroll;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0; /* Safari and Chrome */
    display: none; /* Safari and Chrome */
  }
`;

const pulseColorAnimation = keyframes`
  0% {
    background-color: #ededed;
  }
  50% {
    background-color: #cfcfcf; /* Change to your desired color */
  }
  100% {
    background-color: #ededed;
  }
`;

const Skeleton = styled.div`
  background-color: #ededed;
  border-radius: 20px;
  height: ${(p) => p.h};
  width: ${(p) => p.w};
  animation: ${pulseColorAnimation} 2s infinite;
`;
